// Related compass variables that we use.

// The browser usage threshold for features that gracefully degrade
// Defaults to 1 user in 1,000.
$graceful-usage-threshold: 0.1 !default;

// The browser usage threshold for features that cannot degrade gracefully
// Defaults to 1 user in 10,000.
$critical-usage-threshold: 0.01 !default;

$default-capability-options: (
  (full-support: true),
  (partial-support: true)
);

// Set this to true to generate comments that will explain why a prefix was included or omitted.
$debug-browser-support: false !default;

// When a prefix in in context, but there is no current prefix
// That context is recorded here so other prefixes can be avoided.
$prefix-context: null;

// When a prefix is in a selector or directive scope, this is set to the
// current prefix value.  When `null`, either there is no prefix in scope
// or the official prefix is being rendered. The `$prefix-context`
// variable can be used to make that distinction.
$current-prefix: null;

// When in a context that only exists in a particular version
// this variable is set to those versions.
$current-browser-versions: ();

// The prefixed support threshold for box-sizing.
// Defaults to the $graceful-usage-threshold.
$box-sizing-support-threshold: $critical-usage-threshold !default;

// The default box-sizing model when no argument is provided to the box-sizing mixin: [ content-box | border-box | padding-box ]
//
// The browser default is content-box, compass defaults to border-box.
$default-box-sizing: border-box !default;