// Include the Compass mixin's that we want.
// https://github.com/Compass/compass

@mixin box-sizing($box-model: $default-box-sizing) {
    $box-model: unquote($box-model);
    @include prefixed-properties(css3-boxsizing, $box-sizing-support-threshold, (box-sizing: $box-model));
  }
  
  @mixin prefixed-properties($capability, $threshold, $properties, $capability-options: $default-capability-options) {
    @include with-each-prefix($capability, $threshold, $capability-options) {
      @each $prop, $value in $properties {
        @include prefix-prop($prop, $value);
      }
    }
  }
  
  @mixin with-prefix($prefix) {
    @if $current-prefix or $prefix-context {
      @if $current-prefix == $prefix or $prefix-context == $prefix {
        @if $debug-browser-support {
          @if $prefix {
            /* content for #{$prefix} because #{$current-prefix or $prefix-context} is already in scope. */
          }
          @else {
            /* unprefixed content. #{$current-prefix or $prefix-context} is already in scope. */
          }
        }
        $old-prefix-context: $prefix-context;
        $old-prefix: $current-prefix;
        $prefix-context: $prefix-context or $current-prefix !global;
        $current-prefix: $prefix !global;
        @content;
        $prefix-context: $old-prefix-context !global;
        $current-prefix: $old-prefix !global;
      }
      @else if $prefix == null {
        $old-prefix-context: $prefix-context;
        $prefix-context: $prefix-context or $current-prefix !global;
        $current-prefix: null !global;
        @if $debug-browser-support {
          /* Content for official syntax. Prefix context is still #{$prefix-context}. */
        }
        @content;
        $current-prefix: $prefix-context !global;
        $prefix-context: $old-prefix-context !global;
      }
      @else if $debug-browser-support {
        /* Omitting content for #{$prefix} because #{$current-prefix} is already in scope. */
      }
    }
    @else {
      @if $debug-browser-support and $prefix {
        /* Creating new #{$prefix} context. */
      }
      $prefix-context: $prefix !global;
      $current-prefix: $prefix !global;
      @content;
      $current-prefix: null !global;
      $prefix-context: null !global;
    }
  }
  
  @mixin with-each-prefix($capability, $threshold, $capability-options: $default-capability-options) {
    @each $prefix, $should-use-prefix in prefixes-for-capability($capability, $threshold, $capability-options) {
      @if $should-use-prefix {
        @if $debug-browser-support and type-of($should-use-prefix) == list {
          /* Capability #{$capability} is prefixed with #{$prefix} because #{$should-use-prefix} is required. */
        }
        @else if $debug-browser-support and type-of($should-use-prefix) == number {
          /* Capability #{$capability} is prefixed with #{$prefix} because #{$should-use-prefix}% of users need it which is more than the threshold of #{$threshold}%. */
        }
        @include with-prefix($prefix) {
          @include with-browser-ranges($capability) {
            @content;
          }
        }
      }
      @else if $debug-browser-support {
        /* Capability #{$capability} is not prefixed with #{$prefix} because #{prefix-usage($prefix, $capability, $capability-options)}% of users are affected which is less than the threshold of #{$threshold}. */
      }
    }
    @include with-prefix(null) {
      @include with-browser-ranges($capability) {
        @content;
      }
    }
  }
  
  @mixin with-browser-ranges($capability, $prefix: $current-prefix) {
    $new-ranges: null;
    @if type-of($capability) == map {
      $new-ranges: $capability;
    }
    @else {
      $new-ranges: browser-ranges($capability, $prefix);
    }
    @if has-browser-subset($current-browser-versions, $new-ranges) {
      $old-ranges: $current-browser-versions;
      $current-browser-versions: intersect-browser-ranges($old-ranges, $new-ranges) !global;
      @content;
      $current-browser-versions: $old-ranges !global;
    }
    @else if $debug-browser-support {
      /* Excluding content because #{inspect($new-ranges)} is not included within
         #{inspect($current-browser-versions)} */
    }
  }
  
  @mixin prefix-prop($property, $value, $prefix: $current-prefix) {
    #{prefix-identifier($property, $prefix)}: $value;
  }
  