body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

h1,h2,h3,.h1,.h2,.h3{
    margin-top: 20px;
    margin-bottom: 10px;
}

h4,h5,h6,.h4,.h5,.h6{
    margin-top: 10px;
    margin-bottom: 10px;
}

html *{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, .h1 {
    font-size: $font-size-h1;
    line-height: 1.15em;
}
h2, .h2{
    font-size: $font-size-h2;
    line-height: 1.5em;
}
h3, .h3{
    font-size: $font-size-h3;
    line-height: 1.4em;
}
h4, .h4{
    font-size: $font-size-h4;
    line-height: 1.5em;
}
h5, .h5 {
    font-size: $font-size-h5;
    line-height: 1.55em;
    margin-bottom: 15px;
}
h6, .h6{
    font-size: $font-size-h6;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
}
p{
    font-size: $font-paragraph;
    margin: 0 0 10px;
}

b{
    font-weight: 700;
}

small,
.small{
    font-size: 75%;
    color: #777;
}

.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading{
    font-weight: $font-weight-default;
    font-family: $font-family-titles;

    &,
    a{
        color: $black-color;
        text-decoration: none;
    }
}

h2.title{
    margin-bottom: $headings-margin-bottom * 2;
}

.error-message h2{
    color: $grey-a200;
}

.description,
.card-description{
    color: $gray-light;
}

.footer-big p{
    color: $white;
}

.text-warning {
  color: $brand-warning !important;
}
.text-primary {
  color: $brand-primary !important;
}
.text-danger {
  color: $brand-danger !important;
}
.text-success {
  color: $brand-success !important;
}
.text-info {
  color: $brand-info !important;
}
.text-rose{
    color: $brand-rose !important;
}
.text-gray{
    color: $gray-color !important;
}

.space{
    height: 130px;
    display: block;
}
.space-110{
    height: 110px;
    display: block;
}
.space-50{
    height: 50px;
    display: block;
}
.space-70{
    height: 70px;
    display: block;
}

.blockquote{
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 5px solid #eee;

    p{
        font-size: 1.063rem;
        font-style: italic;
    }

    small{
        display: block;
        font-size: 80%;
        line-height: 1.42857143;
        color: #777;
    }
}
