.custom-search-box {
    @extend .ml-auto;
    @extend .mr-auto;

    width: 100%;
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-family: 'FontAwesome';
        content: '\f002';
        background: $search-box-color;
        text-align: center;
        color: $search-box-icon-color;
        border-radius: 5px;
        -webkit-font-smoothing: subpixel-antialiased;
        font-smooth: always; /* MacOS Only */
    }
}

.custom-search-term {
    @include box-sizing(border-box);
    width: 100%;
    border: 3px solid $search-box-color;
    padding: 5px;
    height: 40px;
    border-radius: 5px;
    outline: none;
}

.custom-search-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    opacity: 0;
    cursor: pointer;
}

