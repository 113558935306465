[class*="blogs-"]{
    padding: 50px 0;
}


.blogs-1{
    .card{
        margin-bottom: 80px;
    }
}

.blogs-4{
    .card{
        margin-bottom: 60px;
        text-align: center;
    }
}

.card-blog{
    .row{
        .card-category{
            margin-bottom: 0;
        }
        .card-description{
            line-height: 1.313rem;
        }
    }
}

.blog-post .section-text{
    img{
        @include img-fluid;
        @extend .rounded;
        @extend .img-raised;
    }
}

.img-gradient:after {
    content:'';
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    border-radius: 6px;
    display:inline-block;
    background: -moz-linear-gradient(top, rgba(132,132,132,0) 50%, rgba(132,132,132,0.12) 63%, rgba(10,14,10,0.34) 88%, rgba(10,8,9,0.45) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(132,132,132,0) 50%,rgba(132,132,132,0.12) 63%,rgba(10,14,10,0.34) 88%,rgba(10,8,9,0.45) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(132,132,132,0) 50%,rgba(132,132,132,0.12) 63%,rgba(10,14,10,0.34) 88%,rgba(10,8,9,0.45) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00848484', endColorstr='#730a0809',GradientType=0 ); /* IE6-9 */
}

.img-gradient img{
    display:block;
}